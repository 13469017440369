import logo from './logo.svg';
import './App.css';
import Home from './thinktrek/Home';
import Privacy from './thinktrek/Privacy';
import Terms from './thinktrek/Terms';
import Refund from './thinktrek/Refund';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
    
     <Routes>
     
     <Route path="/" element={<Home/>} />
     <Route path="/privacy" element={<Privacy/>} />
     <Route path="/terms" element={<Terms/>} /> 
     <Route path="/refund" element={<Refund/>} /> 
     {/* 
     <Route path="support" element={<Support/>} />
     */}
     </Routes>
   
   </BrowserRouter>
    </div>
  );
}

export default App;
