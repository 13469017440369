import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams, useNavigation } from "react-router-dom";
import footlogo from '../thinktrek/images/footer_logo.png'
import logo from '../thinktrek/images/logo.png'



function Refund({ scroll_parent }) {
    const location = useLocation();
    const _activeCheck = location.hash;
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToParent = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view
            const parentSection = document.querySelector('#product');


            if (parentSection) {
                parentSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    const scrollToParentAbout = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view

            const headerSection = document.querySelector('#about')
            if (headerSection) {
                headerSection.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    };


    const d = new Date();
    let year = d.getFullYear();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const [name, setName] = useState('')
    const [name_err, setName_err] = useState('')
    const [email, setEmail] = useState('')
    const [email_err, setEmail_err] = useState('')
    const [query, setQuery] = useState('')
    const [query_err, setQuery_err] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            // Basic validation checks
            if (!name) {
                setName_err("Name is required.");
                setLoading(false)
                return;
            }
            else if (!email) {
                setName_err('')
                setEmail_err("Email is required.");
                setLoading(false)
                return;
            }
            // Email validation
            else if (!isValidEmail.test(email)) {
                setName_err('')
                setEmail_err('Please provide a valid email address');
                setLoading(false)
                return;
            }

            else if (!query) {
                setName_err('');
                setEmail_err('')
                setQuery_err("Message is required.");
                setLoading(false)
                return;
            }

            setName_err('');
            setEmail_err('')
            setQuery_err('')
            // Prepare parameters for the API request
            const params = {
                name: name,
                email: email,
                message: query,
            };

            console.log('Sending request with params:', params);

            // Send request using fetch with async/await
            const response = await fetch(
                'https://staginglocal.redappletech.com/justgame-api/api/v2/contact-us-for-thinktreak',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(params),
                }
            );

            // Check if the response is OK (status 200)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON response
            const data = await response.json();

            // Handle response
            if (data.error === false) {
                handleShow()
                setLoading(false)
                setTimeout(() => {
                    handleClose()
                }, 8000);

                // Clear form fields after successful submission
                setEmail('');
                setName('');
                setQuery('');
            } else {
                alert('Error: ' + data.message);
            }

        } catch (error) {
            // Handle fetch error or any unexpected error
            console.error('Error:', error);
            //alert('An error occurred. Please try again later.');
        }
    };
    return (
        <div>
            <header class="header_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="header_inn">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    <div class="container-fluid">
                                        <a class="navbar-brand" href="/"><img src={logo} alt="" /></a>
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav me-auto justify-content-end w-100">
                                                <li class="nav-item">
                                                    <a class={(_activeCheck == '#about' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" onClick={scrollToParentAbout}>ABOUT US</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class={(_activeCheck == '/#product' || _activeCheck == '') ? `nav-link active` : `nav-link`} onClick={scrollToParent}>PRODUCTS</a>
                                                </li>
                                                {/* <li class="nav-item">
                          <a className='nav-link' target='_blank' href='https://staginglocal.redappletech.com/ragameportal'>Games</a>
                        </li> */}
                                                <li class="nav-item">
                                                    <a class={_activeCheck == '#footer' ? `nav-link active` : `nav-link`} href="#footer" >CONTACT US</a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='main_privacy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='privacy_hed'>
                                <h3>Refund & Cancellation Policy</h3>
                            </div>

                            <div className='privacy_mid'>
                                <div className='privacy_mid_inn'>
                                    <h6>Deposit in User Wallet:</h6>
                                    <p>In compliance with Rule 31B of the Central Goods and Services Tax Rules, 2017 (CGST Rules), effective from October 1, 2023, thinktrek entertainment pvt. ltd. shall levy applicable GST on the amounts deposited by the User.</p>
                                    <p>The Company will issue an invoice for each deposit, detailing the taxable value and applicable GST. If deposits are made via UPI, thinktrek entertainment pvt. ltd. may store the Virtual Payment Address (VPA) to enhance future payment experiences. Users consent to this storage.</p>
                                    <p>thinktrek entertainment pvt. ltd. may retrieve additional VPAs linked to the User's registered mobile number to streamline transactions, and Users consent to this retrieval and storage.</p>
                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>Payment Terms for Games and Contests:</h6>
                                    <p>Users agree to pay contributions for each game, which include:</p>
                                    <p>
                                        <ul>
                                            <li>A Platform Fee for access to the game.</li>
                                            <li>A Contribution towards the Prize Money Pool for the game, which will be awarded to winners as per the game's rules.</li>
                                            <li>The Company reserves the right to deny access to the platform and disqualify any User at its sole discretion.</li>
                                        </ul>
                                    </p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>Role of Payment Collection Agent:</h6>
                                    <p>
                                        <ul>
                                            <li>Users appoint (or a designated third party) as their payment collection agent to manage funds on their behalf.</li>
                                            <li>User funds are held in a non-interest-bearing account managed by the Agent.</li>
                                            <li>The Company does not have any right over the Prize Money Pool, which is managed by the Agent, except for its share of Platform Fees.</li>
                                        </ul>
                                    </p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>User Account Structure:</h6>
                                    <p>
                                        <ul>
                                            <li><span>Play Money:</span> Money deposited by the User.</li>
                                            <li><span>Prize Money:</span> Winnings earned by Users, which can be withdrawn or used to play more games, after applicable taxes.</li>
                                            <li><span>Cashbacks: </span>Discounts offered by thinktrek entertainment pvt. ltd.. These are non-withdrawable and can be used to avail discounts but cannot be redeemed for cash.</li>
                                            <li><span>Bonus Money: </span>Discounts offered in the form of bonus points. These are non-withdrawable and can only be adjusted against Platform Fees.</li>
                                            <li><span>Entry Pass: </span>A non-withdrawable discount scheme offering Users discounts on entry fees, as per the terms communicated by the Company.</li>
                                            <li><span>Special Rewards:</span>Non-withdrawable discounts offered on certain games or contests, used to avail discounts on entry fees.</li>
                                        </ul>
                                    </p>
                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>Withdrawal Process:</h6>
                                    <p>
                                        <ul>
                                            <li>Users can request withdrawals from their Play Money after completing user verification (including PAN card and bank account verification).</li>
                                            <li>Withdrawals will be processed within a reasonable time after all required documents are verified.</li>
                                            <li>Prize Money withdrawals are subject to a nominal processing fee, and TDS may be applicable as per current tax laws.
                                            </li>
                                        </ul>
                                    </p>
                                    <p>Pursuant to Rule 31B of the CGST Rules, the amount available for withdrawal from the Play Money account will be net of GST on deposits made after October 1, 2023.</p>
                                    <p>The Company, at its discretion, may issue refunds for cancellations or exceptional circumstances, after deducting applicable taxes and GST.</p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>TDS Deduction:</h6>
                                    <p>
                                        <ul>
                                            <li>
                                                As per the Income Tax Act, 1961, TDS will be deducted on Net Winnings at the time of withdrawal or at the end of the financial year, whichever is earlier.
                                            </li>
                                            <li>TDS will be deducted at a rate of 30% on the Net Winnings.</li>
                                            <li>Users will be issued a TDS certificate (Form 16A) for their records.</li>
                                        </ul>
                                    </p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>Scheme Terms:</h6>
                                    <p>
                                        <ul>
                                            <li>Cashback, Bonus Money, and other discount schemes offered by thinktrek entertainment pvt. ltd. do not hold any intrinsic value and cannot be redeemed for cash.</li>
                                            <li>The Company reserves the right to modify, cancel, or withdraw any discount schemes or contests without prior notice.</li>

                                        </ul>
                                    </p>
                                    <p>The Company reserves the right to disqualify winners or deny withdrawals if the User fails to meet eligibility criteria or provides false documentation.</p>
                                    <p>The User is responsible for any additional taxes applicable beyond TDS. The Company is not liable for the quality, suitability, or merchantability of any prizes and reserves the right to modify or replace prizes at any time.</p>



                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>Limitation of Liability</h6>
                                    <p>To the fullest extent permitted by law, users access and use thinktrek entertainment pvt. ltd.'s Services at their own risk. The Services and all content are provided on an “AS IS” and “AS AVAILABLE” basis. thinktrek entertainment pvt. ltd. expressly disclaims all warranties and conditions, whether express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. thinktrek entertainment pvt. ltd. makes no warranty and assumes no responsibility or liability for:
                                    </p>
                                    <p>
                                        <ul>
                                            <li>the accuracy, completeness, availability, timeliness, security, or reliability of the Services or any content;</li>
                                            <li>any damage to users’ devices, data loss, or harm arising from accessing or using the Services or content</li>
                                            <li>failure to store, transmit, or delete any content or communication through the Services;</li>
                                            <li>whether the Services will meet user requirements, or be available uninterrupted, secure, or error-free; and</li>
                                            <li>any errors or omissions, including those related to prizes, from thinktrek entertainment pvt. ltd. or third parties.</li>
                                        </ul>
                                    </p>
                                    <p>To the maximum extent permitted by applicable law, thinktrek entertainment pvt. ltd. will not be liable for any indirect, incidental, special, consequential, or punitive damages, or loss of profits, revenues, data, goodwill, or other intangible losses, arising from:</p>
                                    <p>
                                        <ul>
                                            <li>access to, use of, or inability to access or use the Services;</li>
                                            <li>any conduct or content of third parties, including defamatory, offensive, or illegal conduct by other users or third parties;
                                            </li>
                                            <li>any content obtained from the Services;</li>
                                            <li>unauthorized access, use, or alteration of content; or</li>
                                            <li>natural calamities.</li>
                                        </ul>
                                    </p>
                                    <p>In no event will thinktrek entertainment pvt. ltd.'s total liability to any user exceed the amount the user has paid to thinktrek entertainment pvt. ltd. in the six months preceding the claim, or INR 1000, whichever is lower. This limitation applies regardless of the legal theory (warranty, contract, statute, tort, etc.) and even if thinktrek entertainment pvt. ltd. has been advised of the possibility of such damages. Users are solely responsible for the consequences of accessing and using thinktrek entertainment pvt. ltd.'s Services, especially if such actions involve illegal acts or non-compliance with these Terms, including providing false information. Users agree to indemnify thinktrek entertainment pvt. ltd., its officers, directors, employees, and agents for any losses, damages, or legal expenses incurred due to the user's actions, including full attorney fees.</p>

                                    <p>Users agree to indemnify, defend, and hold thinktrek entertainment pvt. ltd. harmless from any claims made by third parties arising from their use of the Services or participation in any contests. thinktrek entertainment pvt. ltd. will not be liable for any acts or omissions related to user's engagement or contest participation.</p>
                                    <p>The failure of thinktrek entertainment pvt. ltd. to enforce any provision of these Terms does not constitute a waiver of that provision. If any part of these Terms is found to be invalid or unenforceable, that provision will be limited to the minimum extent necessary, and the rest of the Terms will continue to remain in effect.</p>
                                    <p>These Terms are governed by the laws of the Republic of India. Users acknowledge that their access to the Services may also be subject to the laws and regulations specific to their state of residence.</p>
                                    <p>All legal claims or disputes arising from the use of the Services will be subject to the exclusive jurisdiction of the courts of Kolkata. Users consent to the jurisdiction and waive any objections based on inconvenient forum.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_sec" id='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer_in">
                                <div className="footer_logo">
                                    <img src={footlogo} alt="" />
                                </div>
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>DELHI</h6>
                                                <div className="footer_btm">
                                                <p><span><i className="fa-solid fa-location-dot"></i></span><span>22/748, DDA Flats, Kalkaji, <br />New Delhi - 110019 <br/> India</span></p>
                                                    {/* <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (011) 4070-4088</span></p> */}
                                                    <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : <a style={{ color: "#fff" }} href='mailto:support@thinktrekent.in'>support@thinktrekent.in</a></span></p>
                                                </div>

                                            </div>

                                            {/* <div className="footer_top mt-5">
                        <h6>KOLKATA</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>Mani Casadona, West Tower, Unit No: 6WS1, 6th floor, Plot No: 11F/04, Action Area: IIF, New Town, Rajarhat, Kolkata- 700156</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (033) 4007-8554</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div> */}
                                        </div>
                                        <div className='footer_priv'>
                                            <ul>
                                                <li><a onClick={() => {
                                                    navigate('/privacy')
                                                }}>Privacy Policy</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/terms')
                                                }}>Terms & Condition</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/refund')
                                                }}>Refund & Cancellation Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>OUR PRODUCTS</h6>
                                                <div className="footer_btm">
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a class={(_activeCheck == '#jgame' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#jgame" >Just Game</a>
                                                        </li>
                                                        {/* <li class="nav-item">
                                                            <a class={_activeCheck == '#playzhub' ? `nav-link active` : `nav-link`} href="#playzhub">Playzhub</a>
                                                        </li>
                                                        
                                                        <li class="nav-item">
                                                            <a class={_activeCheck == '#tryon' ? `nav-link active` : `nav-link`} href="#tryon" >Try On</a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>SUPPORT</h6>
                                                <div className="footer_btm support">
                                                    <form>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{name_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{email_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <textarea placeholder="Write your message" value={query} onChange={(e) => setQuery(e.target.value)}></textarea>
                                                            <span className='err_fil' style={{ color: '#FA4032' }}> {query_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="button" value="submit" disabled={loading ? true : false}
                                                                onClick={onSubmit}
                                                            //onClick={handleShow} 
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer_dwn">
                                <p>Copyright © {year} Thinktrek Entertainment Pvt. Ltd. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Refund