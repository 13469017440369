import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams, useNavigation } from "react-router-dom";
import footlogo from '../thinktrek/images/footer_logo.png'
import logo from '../thinktrek/images/logo.png'



function Privacy({ scroll_parent }) {
    const location = useLocation();
    const _activeCheck = location.hash;
    const navigate = useNavigate()

    const d = new Date();
    let year = d.getFullYear();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToParent = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view
            const parentSection = document.querySelector('#product');


            if (parentSection) {
                parentSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    const scrollToParentAbout = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view

            const headerSection = document.querySelector('#about')
            if (headerSection) {
                headerSection.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    };




    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const [name, setName] = useState('')
    const [name_err, setName_err] = useState('')
    const [email, setEmail] = useState('')
    const [email_err, setEmail_err] = useState('')
    const [query, setQuery] = useState('')
    const [query_err, setQuery_err] = useState('')
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            // Basic validation checks
            if (!name) {
                setName_err("Name is required.");
                setLoading(false)
                return;
            }
            else if (!email) {
                setName_err('')
                setEmail_err("Email is required.");
                setLoading(false)
                return;
            }
            // Email validation
            else if (!isValidEmail.test(email)) {
                setName_err('')
                setEmail_err('Please provide a valid email address');
                setLoading(false)
                return;
            }

            else if (!query) {
                setName_err('');
                setEmail_err('')
                setQuery_err("Message is required.");
                setLoading(false)
                return;
            }

            setName_err('');
            setEmail_err('')
            setQuery_err('')
            // Prepare parameters for the API request
            const params = {
                name: name,
                email: email,
                message: query,
            };

            console.log('Sending request with params:', params);

            // Send request using fetch with async/await
            const response = await fetch(
                'https://staginglocal.redappletech.com/justgame-api/api/v2/contact-us-for-thinktreak',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(params),
                }
            );

            // Check if the response is OK (status 200)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON response
            const data = await response.json();

            // Handle response
            if (data.error === false) {
                handleShow()
                setLoading(false)
                setTimeout(() => {
                    handleClose()
                }, 8000);

                // Clear form fields after successful submission
                setEmail('');
                setName('');
                setQuery('');
            } else {
                alert('Error: ' + data.message);
            }

        } catch (error) {
            // Handle fetch error or any unexpected error
            console.error('Error:', error);
            //alert('An error occurred. Please try again later.');
        }
    };
    return (
        <div>
            <header class="header_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="header_inn">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    <div class="container-fluid">
                                        <a class="navbar-brand" href="/"><img src={logo} alt="" /></a>
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav me-auto justify-content-end w-100">
                                                <li class="nav-item">
                                                    <a class={(_activeCheck == '#about' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" onClick={scrollToParentAbout}>ABOUT US</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class={(_activeCheck == '/#product' || _activeCheck == '') ? `nav-link active` : `nav-link`} onClick={scrollToParent}>PRODUCTS</a>
                                                </li>
                                                {/* <li class="nav-item">
                          <a className='nav-link' target='_blank' href='https://staginglocal.redappletech.com/ragameportal'>Games</a>
                        </li> */}
                                                <li class="nav-item">
                                                    <a class={_activeCheck == '#footer' ? `nav-link active` : `nav-link`} href="#footer" >CONTACT US</a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='main_privacy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='privacy_hed'>
                                <h3>Privacy Policy </h3>
                            </div>

                            <div className='privacy_mid'>
                                <div className='privacy_mid_inn'>
                                    <h6>1. Information Collection:</h6>
                                    <p><span>Personal Information:</span> This could include names, email addresses, phone numbers, dates of birth, and other identifying details provided by users when they register for accounts, make purchases, or contact customer support.</p>
                                    <p><span>Usage Data:</span>  Information about how users interact with Thinktrek's games and platform, such as game progress, in-app purchases, playtime, and device information.</p>
                                    <p><span>Location Data:</span>  If applicable, information about a user's location, which may be collected through GPS or other means.</p>
                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>2. Information Use:</h6>
                                    <p><span>Providing Services:</span>  Thinktrek likely uses collected information to provide and improve its games and platform, such as:</p>
                                    <p>Delivering game content and updates
                                        <ul>
                                            <li>Processing payments</li>
                                            <li>Providing customer support</li>
                                            <li>Personalizing user experiences</li>
                                        </ul>
                                    </p>
                                    <p><span>Marketing and Advertising:</span>  Thinktrek may use data to send promotional emails, display targeted ads within its games or on other platforms, and conduct market research.</p>
                                    <p><span>Legal and Safety:</span>  Thinktrek may use information to comply with legal obligations, prevent fraud, and protect the safety of users and the platform.</p>
                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>3. Data Sharing:</h6>
                                    <p><span>Third-Party Service Providers:</span>  Thinktrek may share user data with third-party service providers that assist with aspects of its business, such as payment processing, analytics, and marketing.</p>
                                    <p><span>Legal Requirements:</span> Thinktrek may disclose user information if required by law or in response to valid legal requests.</p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>4. Data Security:</h6>
                                    <p>Thinktrek likely employs reasonable security measures to protect user data from unauthorized access, use, or disclosure.</p>
                                    <p><span>Encryption:</span> Implementing encryption technologies to protect data both in transit and at rest. This helps to scramble data so that it is unreadable to unauthorized parties.</p>
                                    <p><span>Access Controls:</span> Implementing robust access controls, such as strong passwords, multi-factor authentication, and role-based access, to limit access to sensitive data to authorized personnel.</p>
                                    <p><span>Regular Security Audits and Penetration Testing:</span> Conducting regular security audits and penetration testing to identify and address potential vulnerabilities in their systems and applications.</p>
                                    <p><span>Incident Response Plan:</span> Having a well-defined incident response plan in place to quickly detect, contain, and mitigate any security breaches.</p>
                                    <p><span>Employee Training:</span> Providing security awareness training to employees to educate them about security best practices and the importance of data protection.</p>
                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>5. User Rights:</h6>
                                    <p><span>Data Portability:</span> Users may have the right to receive their personal data in a portable format to transfer it to another service provider.</p>
                                    <p><span>Data Correction:</span> Users may have the right to request the correction of inaccurate or incomplete personal data.</p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>6. Consent and Withdrawal:</h6>
                                    <p><span>Clear and Meaningful Consent:</span> Users may have the right to provide clear and informed consent for the processing of their personal data, and this consent must be freely given, specific, informed, and unambiguous.</p>
                                    <p><span>Easy Withdrawal Process:</span> Users may have the right to withdraw their consent easily and effectively, through a similar process to that used to give consent.6. Children's Privacy:</p>
                                    <p>If Thinktrek's games are targeted at children, the privacy policy would likely address compliance with relevant children's online privacy laws, such as COPPA (Children's Online Privacy Protection Act</p>

                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>7. Changes to the Privacy Policy:</h6>
                                    <p><span>KYC Procedures:</span> KYC procedures also help businesses comply with legal and regulatory requirements related to anti-money laundering (AML) and counter-terrorism financing (CTF).</p>
                                    <p>They contribute to building trust and confidence between businesses and their customers by ensuring that transactions are conducted with legitimate entities.
                                        <ul>
                                            <li>Data Collection: Thinktrek may collect information such as:</li>
                                            <li>Personal Information: Full name, date of birth, address, phone number, email address, government-issued identification (e.g., driver's license, passport).</li>
                                            <li>Financial Information: Bank account details, payment card information.</li>
                                            <li>Proof of Identity: Users may be required to submit copies of government-issued identification documents.</li>
                                        </ul>
                                    </p>
                                    <p>Data Usage: Collected KYC information is likely used to:
                                        <ul>
                                            <li>Verify user identity and prevent fraud.</li>
                                            <li>Comply with anti-money laundering regulations.</li>
                                            <li>Conduct age verification checks (if applicable).</li>
                                            <li>Process financial transactions securely.</li>
                                        </ul>
                                    </p>
                                    <p><span>Data Security:</span> Thinktrek has employed Tappropriate security measures to protect sensitive KYC information.</p>


                                </div>

                                <div className='privacy_mid_inn'>
                                    <h6>8. Other Privacy Policy Details:</h6>
                                    <p>Data Sharing: The privacy policy would likely outline with whom Thinktrek may share user data, including KYC information, such as:
                                        <ul>
                                            <li>Third-party service providers (payment processors, fraud prevention services)</li>
                                            <li>Law enforcement agencies (if required by law)</li>
                                        </ul>
                                    </p>
                                    <p><span>Data Retention:</span> The policy should specify clear retention periods for different types of user data, including KYC information. This ensures that data is not retained for longer than necessary, minimizing the risk of data breaches and complying with data protection regulations.
                                    </p>
                                    <p>Data retention periods should be based on legitimate business needs and legal requirements. Factors to consider include the purpose for which the data was collected, the nature of the data, and any applicable legal obligations (e.g., tax laws, record-keeping requirements).</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_sec" id='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer_in">
                                <div className="footer_logo">
                                    <img src={footlogo} alt="" />
                                </div>
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>DELHI</h6>
                                                <div className="footer_btm">
                                                <p><span><i className="fa-solid fa-location-dot"></i></span><span>22/748, DDA Flats, Kalkaji, <br />New Delhi - 110019 <br/> India</span></p>
                                                    {/* <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (011) 4070-4088</span></p> */}
                                                    <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : <a style={{ color: "#fff" }} href='mailto:support@thinktrekent.in'>support@thinktrekent.in</a></span></p>
                                                </div>

                                            </div>

                                            {/* <div className="footer_top mt-5">
                        <h6>KOLKATA</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>Mani Casadona, West Tower, Unit No: 6WS1, 6th floor, Plot No: 11F/04, Action Area: IIF, New Town, Rajarhat, Kolkata- 700156</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (033) 4007-8554</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div> */}
                                        </div>
                                        <div className='footer_priv'>
                                            <ul>
                                                <li><a onClick={() => {
                                                    navigate('/privacy')
                                                }}>Privacy Policy</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/terms')
                                                }}>Terms & Condition</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/refund')
                                                }}>Refund & Cancellation Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>OUR PRODUCTS</h6>
                                                <div className="footer_btm">
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a class={(_activeCheck == '#jgame' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#jgame" >Just Game</a>
                                                        </li>
                                                        {/* <li class="nav-item">
                                                            <a class={_activeCheck == '#playzhub' ? `nav-link active` : `nav-link`} href="#playzhub">Playzhub</a>
                                                        </li>
                                                        
                                                        <li class="nav-item">
                                                            <a class={_activeCheck == '#tryon' ? `nav-link active` : `nav-link`} href="#tryon" >Try On</a>
                                                        </li> */}

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>SUPPORT</h6>
                                                <div className="footer_btm support">
                                                    <form>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{name_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{email_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <textarea placeholder="Write your message" value={query} onChange={(e) => setQuery(e.target.value)}></textarea>
                                                            <span className='err_fil' style={{ color: '#FA4032' }}> {query_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="button" value="submit" disabled={loading ? true : false}
                                                                onClick={onSubmit}
                                                            //onClick={handleShow} 
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer_dwn">
                                <p>Copyright © {year} Thinktrek Entertainment Pvt. Ltd. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Privacy