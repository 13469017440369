import React, { useState, useRef } from 'react'
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams, useNavigation } from "react-router-dom";
import axios from 'axios';
import plane from '../thinktrek/images/plane.png'
import Banner from '../thinktrek/images/banner.png'
import Banner2 from '../thinktrek/images/think1.png'
import Virtual from '../thinktrek/images/virtual_sec.png'
import Polo from '../thinktrek/images/pllogo.png'
import Mobbg from '../thinktrek/images/mob_bg.png'
import Object1 from '../thinktrek/images/ob1.png'
import Object2 from '../thinktrek/images/ob2.png'
import Object3 from '../thinktrek/images/ob3.png'
import Object4 from '../thinktrek/images/ob4.png'
import Object5 from '../thinktrek/images/ob5.png'
import GooglePlay from '../thinktrek/images/google-play.png'
import AppStore from '../thinktrek/images/app-store.png'
import Banner1 from '../thinktrek/images/banner_01.png'
import Cash from '../thinktrek/images/cash.png'
import Bancross from '../thinktrek/images/ban_cross.png'
import Voucher from '../thinktrek/images/vou.png'
import Women from '../thinktrek/images/v1.png'
import woman1 from '../thinktrek/images/woman1.png'
import Safety from '../thinktrek/images/safety-glasses.png'
import long from '../thinktrek/images/long-wavy-hair-variant.png'
import type1 from '../thinktrek/images/a1.png'
import type2 from '../thinktrek/images/a2.png'
import type3 from '../thinktrek/images/a3.png'
import logo from '../thinktrek/images/logo.png'
import footlogo from '../thinktrek/images/footer_logo.png'
import just from '../thinktrek/images/just.png'
import Plmb1 from '../thinktrek/images/mb1.png'
import Plmb2 from '../thinktrek/images/mb2.png'
import Modal from 'react-bootstrap/Modal';


function Home() {
  const location = useLocation();
  const _activeCheck = location.hash;
  const navigate = useNavigate()
  const handleClickScroll = () => {
    const element = document.getElementById('product');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const [name, setName] = useState('')
  const [name_err, setName_err] = useState('')
  const [email, setEmail] = useState('')
  const [email_err, setEmail_err] = useState('')
  const [query, setQuery] = useState('')
  const [query_err, setQuery_err] = useState('')
  const [loading, setLoading] = useState(false)

  const d = new Date();
  let year = d.getFullYear();

  const onSubmit = async () => {
    setLoading(true)
    try {
      // Basic validation checks
      if (!name) {
        setName_err("Name is required.");
        setLoading(false)
        return;
      }
      else if (!email) {
        setName_err('')
        setEmail_err("Email is required.");
        setLoading(false)
        return;
      }
      // Email validation
      else if (!isValidEmail.test(email)) {
        setName_err('')
        setEmail_err('Please provide a valid email address');
        setLoading(false)
        return;
      }

      else if (!query) {
        setName_err('');
        setEmail_err('')
        setQuery_err("Message is required.");
        setLoading(false)
        return;
      }

      setName_err('');
      setEmail_err('')
      setQuery_err('')
      // Prepare parameters for the API request
      const params = {
        name: name,
        email: email,
        message: query,
      };

      console.log('Sending request with params:', params);

      // Send request using fetch with async/await
      const response = await fetch(
        'https://staginglocal.redappletech.com/justgame-api/api/v2/contact-us-for-thinktreak',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      // Check if the response is OK (status 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();

      // Handle response
      if (data.error === false) {
        handleShow()
        setLoading(false)
        setTimeout(() => {
          handleClose()
        }, 8000);

        // Clear form fields after successful submission
        setEmail('');
        setName('');
        setQuery('');
      } else {
        alert('Error: ' + data.message);
      }

    } catch (error) {
      // Handle fetch error or any unexpected error
      console.error('Error:', error);
      //alert('An error occurred. Please try again later.');
    }
  };
  return (
    <div>

      <header class="header_sec">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="header_inn">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <div class="container-fluid">
                    <a class="navbar-brand" href="/"><img src={logo} alt="" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav me-auto justify-content-end w-100">
                        <li class="nav-item">
                          <a class={(_activeCheck == '#about' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#about" >ABOUT US</a>
                        </li>
                        <li class="nav-item">
                          <a class={_activeCheck == '/#product' ? `nav-link active` : `nav-link`} onClick={handleClickScroll}>PRODUCTS</a>
                        </li>
                        {/* <li class="nav-item">
                          <a className='nav-link' target='_blank' href='https://staginglocal.redappletech.com/ragameportal'>Games</a>
                        </li> */}
                        <li class="nav-item">
                          <a class={_activeCheck == '#footer' ? `nav-link active` : `nav-link`} href="#footer" >CONTACT US</a>
                        </li>
                      </ul>

                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>


      <section className="banner_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
              <div className="ban_lt">
                <img className="w-100" src={plane} alt="" />
              </div>
            </div>
            <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
              <div className="ban_rt">
                <h3>WE ARE PASSIONATE FOR <br />CREATING IMMERSIVE <br />GAMING EXPERIENCES.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec" id='about'>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="about_inn">
                <h5>ABOUT <span>US</span></h5>
                <div className="row">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog">
                      <p>we are passionate about pushing the boundaries of gaming technology to create immersive experiences that captivate players worldwide. With a dedicated team of innovative engineers, designers, and gaming enthusiasts, we strive to redefine the gaming landscape through cutting-edge products and groundbreaking advancements.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type1} alt="" />
                    </div>
                  </div>
                </div>

                <div className="mob_off">
                  <div className="row ">
                    <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                      <div className="about_img">
                        <img className="w-100" src={type2} alt="" />
                      </div>
                    </div>
                    <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                      <div className="about_blog" style={{ paddingRight: 0, paddingLeft: 100 }}>
                        <p className="text-end">Since our inception, we have remained committed to delivering high-quality gaming solutions that cater to the diverse needs of gamers across all platforms. Our expertise spans across various domains, that seamlessly integrate innovation and entertainment. </p>
                        <p className="text-end">At Thinktrek, we believe that gaming is more than just a hobby—it's a gateway to boundless creativity, endless possibilities, and unforgettable experiences.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mob_on">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog" >
                      <p className="text-end">Since our inception, we have remained committed to delivering high-quality gaming solutions that cater to the diverse needs of gamers across all platforms. Our expertise spans across various domains, that seamlessly integrate innovation and entertainment. </p>
                      <p className="text-end">At Thinktrek, we believe that gaming is more than just a hobby—it's a gateway to boundless creativity, endless possibilities, and unforgettable experiences.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type2} alt="" />
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12">
                    <div className="about_blog">
                      <p>As a company deeply rooted in the gaming community, we understand the importance of fostering meaningful connections and collaborations. That's why we actively engage with gamers, developers, and industry experts to gather insights, solicit feedback, and spark inspiration for our next generation of products. Welcome to the next level of gaming.</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12">
                    <div className="about_img">
                      <img className="w-100" src={type3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about_inn" id='product'>
        <h5><span>Our</span> Products</h5>
      </div>
      <section className="main_banner" id='jgame' style={{ backgroundImage: `url(${Banner})` }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="main_ban_lt">
                <img className="w-100 animate__animated animate__fadeInLeft" src={Banner1} alt='' />
              </div>
            </div>
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="main_ban_rt">
                <img className="ob2_sec wow animate__animated animate__fadeInTopLeft" src={Object2} alt='' />
                <img className="ob3_sec wow animate__animated animate__fadeInTopRight" style={{ animationDelay: ".85s" }} src={Object3} alt='' />
                {/* <h6 className="wow animate__animated animate__shakeX">PLAY, WIN & SHOP</h6> */}
                <img style={{ width: "48%", textAlign: "center", marginBottom: 12 }} src={just} alt='' />
                <h2 className="wow animate__animated animate__fadeInRight">PLAY & WIN</h2>
                <div className="lakh_sec wow animate__animated animate__zoomIn" style={{ animationDelay: ".5s" }}>
                  <img className="ob1_sec" src={Object1} alt='' />
                  <img src={Cash} alt='' />
                </div>
                {/* <div className="shopping_sec wow animate__animated animate__fadeInUp" style={{ animationDelay: ".95s" }}>
                  <img src={Voucher} alt='' />
                </div> */}
                <img className="ob4_sec wow animate__animated animate__fadeInBottomLeft" style={{ animationDelay: "1.15s" }} src={Object4} alt='' />
                <img className="ob5_sec wow animate__animated animate__fadeInBottomRight" style={{ animationDelay: ".95s" }} src={Object5} alt='' />
                {/* <div className="play_store_sec wow animate__animated animate__zoomIn" style={{ animationDelay: "1.55s" }}>
                  <img src={GooglePlay} alt='' />
                  <img src={AppStore} alt='' />
                </div> */}
                {/* <p className="knowmore"><a target="_blank" href="https://justgame.in/">Know More</a></p> */}
                <p className="knowmore" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: 24 }}>VISIT <a target="_blank" style={{fontSize:24, textDecoration:"none"}} href="https://justgame.in/"> WWW.JUSTGAME.IN</a> FOR MORE DETAILS</p>
                <img className="ban_crs" src={Bancross} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="main_banner think" id='playzhub' >
        <img className='w-100' src={Banner2} alt='' />
        <p className="play_now"><a target="_blank" href="https://playzhub.com/">Play Now</a></p>
      </section>

      <section className='mob_playz' id='playzhub1' style={{ backgroundImage: `url(${Mobbg})` }}>
        <div className='mob_playz_logo'>
          <img src={Polo} alt=''/>
        </div>
        <div className='mob_back'>
          <h6>LIGHTHEARTED GAMES FOR <br/>JOYFUL BEGINNINGS!</h6>
        </div>
        <div className='mb1'>
          <img src={Plmb1} alt=''/>
        </div>
        <div className='mb1'>
          <img src={Plmb2} alt=''/>
        </div>
        <p className="play_now"><a target="_blank" href="https://playzhub.com/">Play Now</a></p>
      </section> */}


      {/* <section className="betsto_win_sec" style="background: url('images/betstowin.png') no-repeat center center;">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="betstowin_inn">
                <img src="images/betlogo.png" alt=""/>
                 <h4>ONLINE <span>CASINO GAMES</span> PROVIDER</h4>
                 <p>Step into the world of endless entertainment with our wide range of engaging casino games. From classNameic slots to thrilling table & card games, our games are designed to provide an immersive gaming experience for all players</p>


                 <div className="bet_game">
                  <div className="bet_game_in">
                    <img src="images/l1.png" alt=""/>
                    <p>Slots</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l2.png" alt=""/>
                    <p>Table Casinos</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l3.png" alt=""/>
                    <p>Crash Games</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l4.png" alt=""/>
                    <p>Virtual Sports</p>
                  </div>

                  <div className="bet_game_in">
                    <img src="images/l5.png" alt=""/>
                    <p>Poker Software</p>
                  </div>
                 </div>

                 <p className="knowmore"><a href="">KnowMore</a></p>
               </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="virtual_sec" id='tryon' style={{ backgroundImage: `url(${Virtual})` }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="virtual_lt" style={{ backgroundImage: `url(${Women})` }}>

              </div>
            </div>
            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="virtual_rt">
                <h6>AI-POWERED</h6>
                <h5>VIRTUAL</h5>
                <h2>TRY-ON</h2>

                <div className="virtual_btm">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={woman1} alt="" />
                      </div>
                      <p>Face Makeup</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={Safety} alt="" />
                      </div>
                      <p>Eye Glasses</p>
                    </div>
                    <div className="col-sm-4">
                      <div className="virtual_top">
                        <img src={long} alt="" />
                      </div>
                      <p>Hair Makeup</p>
                    </div>

                    <p className="knowmore" ><a style={{ textDecoration: "none" }} href="#">Coming Soon</a></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <footer className="footer_sec" id='footer'>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="footer_in">
                <div className="footer_logo">
                  <img src={footlogo} alt="" />
                </div>
                <div className="row">
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>DELHI</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>22/748, DDA Flats, Kalkaji, <br />New Delhi - 110019 <br/> India</span></p>
                          {/* <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (011) 4070-4088</span></p> */}
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : <a style={{ color: "#fff" }} href='mailto:support@thinktrekent.in'>support@thinktrekent.in</a></span></p>
                        </div>

                      </div>

                      {/* <div className="footer_top mt-5">
                        <h6>KOLKATA</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>Mani Casadona, West Tower, Unit No: 6WS1, 6th floor, Plot No: 11F/04, Action Area: IIF, New Town, Rajarhat, Kolkata- 700156</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (033) 4007-8554</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div> */}
                    </div>
                    <div className='footer_priv'>
                      <ul>
                        <li><a onClick={() => {
                          navigate('/privacy')
                        }} >Privacy Policy</a></li>
                        <li><a onClick={() => {
                          navigate('/terms')
                        }}>Terms & Condition</a></li>
                        <li><a onClick={() => {
                          navigate('/refund')
                        }}>Refund & Cancellation Policy</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>OUR PRODUCTS</h6>
                        <div className="footer_btm">
                          <ul>
                            <li class="nav-item">
                              <a class={(_activeCheck == '#jgame' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#jgame" >Just Game</a>
                            </li>
                            {/* <li class="nav-item">
                              <a class={_activeCheck == '#playzhub' ? `nav-link active` : `nav-link`} href="#playzhub">Playzhub</a>
                            </li>
                            
                            <li class="nav-item">
                              <a class={_activeCheck == '#tryon' ? `nav-link active` : `nav-link`} href="#tryon" >Try On</a>
                            </li> */}
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                    <div className="footer_lt">
                      <div className="footer_top">
                        <h6>SUPPORT</h6>
                        <div className="footer_btm support">
                          <form>
                            <div className="foot1">
                              <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                              <span className='err_fil' style={{ color: '#FA4032' }}>{name_err}</span>
                            </div>
                            <div className="foot1">
                              <input type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                              <span className='err_fil' style={{ color: '#FA4032' }}>{email_err}</span>
                            </div>
                            <div className="foot1">
                              <textarea placeholder="Write your message" value={query} onChange={(e) => setQuery(e.target.value)}></textarea>
                              <span className='err_fil' style={{ color: '#FA4032' }}> {query_err}</span>
                            </div>
                            <div className="foot1">
                              <input type="button" value="submit" disabled={loading ? true : false}
                                onClick={onSubmit}
                              //onClick={handleShow} 
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="footer_dwn">
                <p>Copyright © {year} Thinktrek Entertainment Pvt. Ltd. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>



      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='sub_modal'
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6><span>THANK</span> YOU</h6>
          <p>We’ve received your request and appreciate you reaching out to us. Our team is reviewing your message and will get back to you as soon as possible.</p>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </div>
  )
}


export default Home








