import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams } from "react-router-dom";
import footlogo from '../thinktrek/images/footer_logo.png'
import logo from '../thinktrek/images/logo.png'

function Terms() {

    const location = useLocation();
    const _activeCheck = location.hash;
    const navigate = useNavigate()
    const scrollToParent = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view
            const parentSection = document.querySelector('#product');


            if (parentSection) {
                parentSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const scrollToParentAbout = () => {
        // Navigate to the parent route
        navigate('/');

        // Use a small delay to allow the navigation to happen before scrolling
        setTimeout(() => {
            // Find the parent section and scroll into view

            const headerSection = document.querySelector('#about')
            if (headerSection) {
                headerSection.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    };


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const [name, setName] = useState('')
    const [name_err, setName_err] = useState('')
    const [email, setEmail] = useState('')
    const [email_err, setEmail_err] = useState('')
    const [query, setQuery] = useState('')
    const [query_err, setQuery_err] = useState('')
    const [loading, setLoading] = useState(false)

    const d = new Date();
    let year = d.getFullYear();

    const onSubmit = async () => {
        setLoading(true)
        try {
            // Basic validation checks
            if (!name) {
                setName_err("Name is required.");
                setLoading(false)
                return;
            }
            else if (!email) {
                setName_err('')
                setEmail_err("Email is required.");
                setLoading(false)
                return;
            }
            // Email validation
            else if (!isValidEmail.test(email)) {
                setName_err('')
                setEmail_err('Please provide a valid email address');
                setLoading(false)
                return;
            }

            else if (!query) {
                setName_err('');
                setEmail_err('')
                setQuery_err("Message is required.");
                setLoading(false)
                return;
            }

            setName_err('');
            setEmail_err('')
            setQuery_err('')
            // Prepare parameters for the API request
            const params = {
                name: name,
                email: email,
                message: query,
            };

            console.log('Sending request with params:', params);

            // Send request using fetch with async/await
            const response = await fetch(
                'https://staginglocal.redappletech.com/justgame-api/api/v2/contact-us-for-thinktreak',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(params),
                }
            );

            // Check if the response is OK (status 200)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Parse the JSON response
            const data = await response.json();

            // Handle response
            if (data.error === false) {
                handleShow()
                setLoading(false)
                setTimeout(() => {
                    handleClose()
                }, 8000);

                // Clear form fields after successful submission
                setEmail('');
                setName('');
                setQuery('');
            } else {
                alert('Error: ' + data.message);
            }

        } catch (error) {
            // Handle fetch error or any unexpected error
            console.error('Error:', error);
            //alert('An error occurred. Please try again later.');
        }
    };
    return (
        <div>
            <header class="header_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="header_inn">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    <div class="container-fluid">
                                        <a class="navbar-brand" href="/"><img src={logo} alt="" /></a>
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav me-auto justify-content-end w-100">
                                                <li class="nav-item">
                                                    <a class={(_activeCheck == '#about' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" onClick={scrollToParentAbout} >ABOUT US</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class={_activeCheck == '#product' ? `nav-link active` : `nav-link`} onClick={scrollToParent}>PRODUCTS</a>
                                                </li>
                                                {/* <li class="nav-item">
                          <a className='nav-link' target='_blank' href='https://staginglocal.redappletech.com/ragameportal'>Games</a>
                        </li> */}
                                                <li class="nav-item">
                                                    <a class={_activeCheck == '#footer' ? `nav-link active` : `nav-link`} href="#footer" >CONTACT US</a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='main_privacy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='privacy_hed'>
                                <h3>Terms & Condition </h3>
                            </div>
                            <div className='terms_mid'>
                                <div className='terms_mid_inn'>
                                    <p>Welcome to www.thinktrekentertainment.com (the website), operated by thinktrek entertainment pvt. ltd. ("thinktrek", "we", "us", or "our"). This website provides access to a variety of content and services related to various games - mostly casual games ("services"), as well as opportunities for you to share feedback, and engage in activities related to the services offered.</p>
                                    <p>
                                        By visiting and using this website, www.thinktrekentertainment.com, and playing the games, you agree to these terms and conditions. If you do not agree with any part of these terms, please exit the website immediately. We may update these terms at any time, with or without notice. Your continued use of the website after such changes will be considered as your acceptance of the revised terms.
                                    </p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>Definitions:</h6>
                                    <p>Content refers to all materials on this website, including but not limited to text, images, software applications, animation files, graphics, video and audio files, and any other media or information available on the website.</p>
                                    <p>User refers to any individual who accesses this website through any means, whether legal or illegal, authorized or unauthorized.</p>
                                    <p>User Content refers to all materials submitted or posted by a user, including but not limited to text, images, game development  applications, animation files, graphics, video and audio files, and any other content shared by a user on the platform.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>Intellectual Property:</h6>
                                    <p>All Content available on the Think Trek platform is the exclusive property of Think Trek Entertainment Pvt Ltd or its content providers. This content is protected by applicable copyright, trademark, and other intellectual property laws. Access to Think Trek's intellectual property and services on the platform is permitted solely for the intended purpose for which the platform and services are provided. By using this platform, no ownership rights, licenses, or interests are transferred to you, whether proprietary or otherwise, in any intellectual property or other rights associated with the platform or its content.</p>
                                    <p>You are granted permission to view, play games, Downloading images, audio, and video from the platform for personal, informational, and non-commercial use only, unless stated otherwise. Any modification, copying, distribution, transmission, display, performance, reproduction, printing, publication, licensing, alteration, creation of derivative works, transfer, or sale of any content from the platform is prohibited without prior written consent from Think Trek. You are required to obtain permission from Think Trek before using any content on external platforms or networks.</p>
                                    <p>The website, all content, and associated intellectual property rights remain the sole property of Think Trek or its licensors. Removal of copyright, trademark, or other proprietary notices from any content is strictly forbidden.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>User Content:</h6>
                                    <p>By submitting any User Content to Think Trek through email or other means, you grant Think Trek a perpetual, non-exclusive, irrevocable, worldwide, royalty-free right and license to use, copy, download, upload, display, distribute, exhibit, modify, alter, create derivative works from, and otherwise use such content in any manner or medium, whether now known or hereafter developed. This includes the right to share, perform, or sublicense the content, without obligation for recognition, compensation, or reward to you as the contributor.</p>
                                    <p>Think Trek reserves the right to use the submitted User Content in any way it deems appropriate, and is not liable for any damages or consequences that may arise from the use, modification, or incorporation of your content into Think Trek's products, services, or platforms, including if such alterations resemble the User Content.</p>
                                    <p>By posting any User Content, you represent and warrant that you have the legal right to do so and that your submission does not infringe on the intellectual property or other rights of any third party. You are solely responsible for ensuring that your User Content does not violate any third-party rights.</p>
                                    <p>You agree to indemnify and hold harmless Think Trek, its affiliates, subsidiaries, contractors, agents, and employees from any liability, claims, or damages arising from the use, distribution, or sublicense of your User Content. This includes cooperating with Think Trek's reasonable requests in relation to any claims or legal actions that may arise from your submitted content.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>Links to Third-Party Websites:</h6>
                                    <p>The Think Trek platform may contain links to external websites that are not owned or operated by Think Trek. These links are provided for your convenience and do not imply any endorsement or responsibility on the part of Think Trek for the content, materials, products, or services available on such third-party websites. Think Trek does not control, monitor, or verify the accuracy or legality of content found on linked websites and makes no representations or warranties regarding the nature or reliability of these sites.</p>
                                    <p>Accessing third-party websites through links on the Think Trek platform is done at your own risk. Think Trek disclaims any responsibility or liability for any damages or issues that may arise from interacting with external websites, including any payments made or services used on those sites. We strongly encourage you to review the privacy policies and terms of service of any external websites you visit.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>User Content:</h6>
                                    <p>By using the Think Trek platform, you agree not to employ any automated tools, bots, viruses, or artificial intelligence programs that could disrupt or interfere with the normal functioning of the platform. You also agree not to intercept, emulate, or redirect any communication protocols used by Think Trek, including but not limited to reverse engineering or modifying any part of the platform or its files.</p>
                                    <p>You may not frame, alter, or manipulate the platform in any way, nor add editorial comments, commercial materials, or other unauthorized information. You are prohibited from removing, obscuring, or modifying any proprietary notices or labels that appear on the platform.</p>
                                    <p>You agree not to post any content or comments that may harm or defame Think Trek or its affiliates, whether on publicly accessible media or other platforms, whether or not controlled by Think Trek. In our sole discretion, Think Trek reserves the right to remove any such material or comments and restrict your access to any public media or forums controlled or moderated by us if they are deemed harmful to our business interests.</p>
                                    <p>Think Trek may take appropriate actions in response to violations of these Terms of Service, including but not limited to removing offensive content or User Content, and pursuing any necessary legal actions at your expense and risk.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>Governing Law:</h6>
                                    <p>Any disputes arising from the use of the Think Trek platform, the interpretation or enforcement of these Terms of Service, or any related matters, shall be governed by the laws of India. Such disputes will be subject to the exclusive jurisdiction of the courts located in Delhi, India.</p>
                                </div>
                                <div className='terms_mid_inn'>
                                    <h6>Disclaimer of Warranties:</h6>
                                    <p>Your use of the Think Trek platform is at your own risk. All content, materials, and services provided through the platform are offered on an "as is" basis, without any warranties of any kind, whether express or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property rights. Think Trek makes no representations or warranties regarding the accuracy, completeness, or reliability of any content or games available on the platform. Some of the materials or games provided may be outdated, and Think Trek does not assume any responsibility to update them. The limitations on warranties will not apply in cases where such exclusions are prohibited by law.</p>
                                </div>

                                <div className='terms_mid_inn'>
                                    <h6>Limitation of Liability and Indemnity:</h6>
                                    <p>Think Trek, along with its affiliates, subsidiaries, management, officers, directors, and employees, shall not be held responsible or liable for any damages of any kind, including but not limited to direct, indirect, incidental, consequential, special, punitive, or general damages. This includes any damages arising from breach of contract, loss of profits, business disruption, data loss, negligence, errors, omissions, or destruction of content on the platform. Additionally, Think Trek is not liable for unauthorized access or alteration of the platform’s content by third parties, or any other issues related to the transmission of materials or other actions related to the use or unavailability of the platform. Any decisions made based on the information provided on the platform or the content from external links connected through the platform are done at your own risk.</p>
                                    <p>This limitation of liability applies even if Think Trek has been informed of the potential for such damages.</p>
                                    <p>You agree to indemnify and hold harmless Think Trek, its affiliates, subsidiaries, management, officers, directors, contractors, agents, and employees against any liability, including civil, criminal, or quasi-criminal actions, arising from your use of the platform. You are fully responsible for any costs, claims, or legal actions resulting from your actions, including settlements or agreements made before or after formal proceedings, and for ensuring your use of the platform complies with all applicable laws.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer_sec" id='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer_in">
                                <div className="footer_logo">
                                    <img src={footlogo} alt="" />
                                </div>
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>DELHI</h6>
                                                <div className="footer_btm">
                                                <p><span><i className="fa-solid fa-location-dot"></i></span><span>22/748, DDA Flats, Kalkaji, <br />New Delhi - 110019 <br/> India</span></p>
                                                    {/* <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (011) 4070-4088</span></p> */}
                                                    <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : <a style={{ color: "#fff" }} href='mailto:support@thinktrekent.in'>support@thinktrekent.in</a></span></p>
                                                </div>

                                            </div>

                                            {/* <div className="footer_top mt-5">
                        <h6>KOLKATA</h6>
                        <div className="footer_btm">
                          <p><span><i className="fa-solid fa-location-dot"></i></span><span>Mani Casadona, West Tower, Unit No: 6WS1, 6th floor, Plot No: 11F/04, Action Area: IIF, New Town, Rajarhat, Kolkata- 700156</span></p>
                          <p><span><i className="fa-solid fa-phone"></i></span><span>Phone : +91 (033) 4007-8554</span></p>
                          <p><span><i className="fa-regular fa-envelope"></i></span><span>Email : info@.thinktrekent.com</span></p>
                        </div>

                      </div> */}
                                        </div>
                                        <div className='footer_priv'>
                                            <ul>
                                                <li><a onClick={() => {
                                                    navigate('/privacy')
                                                }}>Privacy Policy</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/terms')
                                                }}>Terms & Condition</a></li>
                                                <li><a onClick={() => {
                                                    navigate('/refund')
                                                }}>Refund & Cancellation Policy</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>OUR PRODUCTS</h6>
                                                <div className="footer_btm">
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a class={(_activeCheck == '#jgame' || _activeCheck == '') ? `nav-link active` : `nav-link`} aria-current="page" href="#jgame" >Just Game</a>
                                                        </li>
                                                        {/* <li class="nav-item">
                                                            <a class={_activeCheck == '#playzhub' ? `nav-link active` : `nav-link`} href="#playzhub">Playzhub</a>
                                                        </li>
                                                       
                                                        <li class="nav-item">
                                                            <a class={_activeCheck == '#tryon' ? `nav-link active` : `nav-link`} href="#tryon" >Try On</a>
                                                        </li> */}

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                        <div className="footer_lt">
                                            <div className="footer_top">
                                                <h6>SUPPORT</h6>
                                                <div className="footer_btm support">
                                                    <form>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{name_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            <span className='err_fil' style={{ color: '#FA4032' }}>{email_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <textarea placeholder="Write your message" value={query} onChange={(e) => setQuery(e.target.value)}></textarea>
                                                            <span className='err_fil' style={{ color: '#FA4032' }}> {query_err}</span>
                                                        </div>
                                                        <div className="foot1">
                                                            <input type="button" value="submit" disabled={loading ? true : false}
                                                                onClick={onSubmit}
                                                            //onClick={handleShow} 
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer_dwn">
                                <p>Copyright © {year} Thinktrek Entertainment Pvt. Ltd. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Terms